<template>
  <v-card dense class="d-flex align-content-end flex-wrap" :loading="loading">
    <v-form
      @submit.prevent="saveInventory"
      autocomplete="off"
      ref="form"
      style="width: 100%"
    >
      <div class="mt-8 mb-8" v-if="!inventory">Loading Inventory..</div>
      <v-card-title v-if="inventory">
        {{ actionPrefix }} Ending Inventory for {{ formatDate(inventory.dateStr) }}<span
          class="ml-2"
          v-if="inventory.statusId !== endInventoryStatusCode.Open"
          >({{ inventoryStatusName }})</span>        
        <v-btn
          dense
          v-if="false && !showFilter"
          class="ml-2"
          color="primary"
          title="Display Filter"
          @click="toggleFilter()"
        >
          <span class="mr-1">Show Filter</span>
          <v-icon>mdi-text-search</v-icon>
        </v-btn>
        <v-btn
          dense
          v-if="false && showFilter"
          class="ml-2"
          color="primary"
          title="Display Filter"
          @click="toggleFilter()"
        >
          <span class="mr-1">Hide Filter</span>
          <v-icon>mdi-text-search</v-icon>
        </v-btn>        
      </v-card-title>
      <v-card-title>
        <div class="v-input theme--light v-text-field v-text-field--is-booted v-text-field--placeholder">
          <div class="v-input__control">
            <div class="v-input__slot">
              <div class="v-text-field__slot">                
                <input type="text" placeholder="Filter" style="width:100%" :value="searchText" @input="updateSearchTextValue" />
              </div>
            </div>
          </div>
        </div>              
      </v-card-title>
      <v-card-text>
        <v-container fluid v-if="inventory">
          <v-row
            :style="getAlternateRowStyle(index)"
            no-gutters
            v-for="(item, index) in inventory.items"
            :key="index"
            v-show="!searchText || (item.num + item.name).toLowerCase().indexOf(searchText.toLowerCase()) >= 0"
          >
            <v-col xs="2" sm="2" md="2" lg="2" class="pr-1">
              <label
                class="d-block"
                v-if="index === 0"
                @click="sortColumn('num')"
                ><b class="mr-2"><u>Item #</u></b
                ><v-icon v-if="sortedColumn == 'num'" small>{{
                  sortIcon
                }}</v-icon></label
              >
              <span>{{ item.num }}</span>
            </v-col>
            <v-col xs="3" sm="5" md="5" lg="5" class="pr-1">
              <label
                class="d-block"
                v-if="index === 0"
                @click="sortColumn('name')"
                ><b class="mr-2"><u>Item Name</u></b
                ><v-icon v-if="sortedColumn == 'name'" small>{{
                  sortIcon
                }}</v-icon></label
              >
              <span>{{ item.name }}</span>
            </v-col>
            <v-col xs="3" sm="2" md="2" lg="2" class="pr-1">
              <label class="d-block" v-if="index === 0"><b>Quantity</b></label>
              <v-text-field
                :background-color="editableBgColor"
                hide-details="auto"
                dense
                :rules="[formValidationRules.required]"
                type="number"
                v-model="item.quantity"
                maxlength="5"
                :readonly="!isEditable"
                :disabled="item.itemId == 0"
              />
            </v-col>
            <v-col xs="3" sm="2" md="2" lg="2">
              <label class="d-block" v-if="index === 0"><b>UoM</b></label>
              <select
                v-bind:style="{ 'background-color': editableBgColor }"
                class="mt-1"
                v-model="item.unitId"
                :disabled="!isEditable"
              >
                <option
                  v-for="(unit, ind) in item.units"
                  :key="ind"
                  :value="unit.id"
                >
                  {{ unit.name }}
                </option>
              </select>
              <!----v-select dense hide-no-data hide-details="auto"
                              v-if="item.isDirty"
                              :readonly="isEditable"
                              v-model="item.unitId"
                              :items="item.units"
                              :loading="loading"
                              item-text="name"
                              item-value="id"
                              @change="onItemChanged">
                    </!--v-select-->
            </v-col>
            <v-col xs="2" sm="1" md="1" lg="1" class="pr-1">
              <label class="d-block" v-if="index === 0"><b>Prev</b></label>
              <span>{{item.prevQty}}</span> <span v-if="item.prevQty">{{item.prevUnit}}</span>
            </v-col>
          </v-row>
          <v-row
            :style="getAlternateRowStyle(inventory.items.length + index)"
            no-gutters
            v-for="(item, index) in customItems"
            :key="'A' + index"
          >
            <v-col xs="11" sm="11" md="11" lg="11">
              <v-text-field
                :background-color="editableBgColor"
                hide-details="auto"
                dense
                :rules="[formValidationRules.required]"
                v-model="item.description"
                maxlength="2000"
                :readonly="!isEditable"
              />
            </v-col>
            <v-col xs="1" sm="1" md="1" lg="1">
              <v-icon
                class="mr-1"
                title="Remove Custom Item"
                @click="removeCustomItem(item)"
                :disable="loading"
              >
                mdi-delete
              </v-icon>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="inventory && false">
            <v-col class="col1">
              <label><b>New Item</b></label>
              <v-autocomplete
                dense
                clearable
                hide-no-data
                hide-selected
                v-model="newItem.itemId"
                :items="itemLookup"
                :loading="loading"
                item-text="displayName"
                item-value="itemId"
                placeholder="Start typing to Search"
                @change="onNewItemChanged"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-dialog v-model="showNewCustomItemDialog" max-width="600px">                      
            <v-card>
              <v-card-title>
                <span class="text-h5">Add Custom Item</span>
              </v-card-title>
              <v-card-subtitle>
                Please provide as much detail as possible about the custom item that you are adding (item number, item description, quantity, unit of measure) 
              </v-card-subtitle>
              <v-card-text v-if="showNewCustomItemDialog">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea                                                
                        v-model="newCustomItem.description"
                        maxlength="2000"                        
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="cancelAddCustomItem">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="acceptNewCustomItem"> Add to Inventory </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-app-bar bottom fixed color="secondary" dark class="d-print-none">
          <v-card-actions>
            <v-btn dense color="primary" title="Go Back" @click="goBack()">
              <v-icon>mdi-arrow-left-thick</v-icon>
            </v-btn>
            <v-btn
              v-if="isEditable"
              dense
              color="primary"
              title="Save Inventory"
              @click="saveInventory()"
              :loading="loading"
            >
              Save Inventory
            </v-btn>
            <v-btn
              v-if="showSubmit"
              dense
              color="primary"
              title="Submit Inventory for Approval"
              @click="submitInventory()"
              :loading="loading"
            >
              Submit Inventory for Approval
            </v-btn>
            <v-btn
              v-if="showClose"
              dense
              color="primary"
              title="Close Inventory"
              @click="closeInventory()"
              :loading="loading"
            >
              Approve (Close) Inventory
            </v-btn>
            <v-btn
              v-if="showReject"
              dense
              color="primary"
              title="Reopen Inventory"
              @click="rejectInventory()"
              :loading="loading"
            >
              Reopen Inventory
            </v-btn>
            <v-btn
              v-if="inventory && isEditable"
              dense
              color="primary"
              title="Add Custom Item"
              @click="addCustomItem()"
              :loading="loading"
            >
              Add Custom Item
            </v-btn>
          </v-card-actions>
        </v-app-bar>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
  //import Auth from "../../mixins/auth";
  //import Common from "../../mixins/common";
  import _debounce from 'lodash/debounce';
  import http from "../../http";
  export default {
    name: "EditInventory",
    //mixins: [Common],
    data() {
      return {
        inventory: null,
        newItem: {
          itemId: 0,
        },
        sortAsc: true,
        newCustomItem: null,        
        sortedColumn: "num",
        showFilter: false,
        searchText: "",           
        loading: false,
        itemLookup: [],
        unitLookup: [],
      };
    },
    watch: {

    },
    computed: {
      showNewCustomItemDialog() {
        return this.newCustomItem !== null;
      },
      isEditable() {
        return (
          this.inventory &&
          this.inventoryContext &&
          this.inventoryContext.isEditingAllowed &&
          this.inventory.statusId !== this.endInventoryStatusCode.Closed &&
          (this.isUserRole(this.roleCode.Manager) ||
            this.isUserRole(this.roleCode.SuperAdmin) ||
            (this.isUserRole(this.roleCode.User) &&
              (this.inventory.statusId === this.endInventoryStatusCode.Open ||
                this.inventory.statusId === this.endInventoryStatusCode.Rejected)))
        );
      },
      showClose() {
        return (
          this.inventory &&
          (this.inventory.statusId ===
            this.endInventoryStatusCode.AwaitingApproval ||
            this.inventory.statusId === this.endInventoryStatusCode.Rejected) &&
          (this.isUserRole(this.roleCode.Manager) ||
            this.isUserRole(this.roleCode.SuperAdmin))
        );
      },
      showReject() {
        return (
          this.inventory &&
          this.inventory.statusId ===
            this.endInventoryStatusCode.AwaitingApproval &&
          (this.isUserRole(this.roleCode.Manager) ||
            this.isUserRole(this.roleCode.SuperAdmin))
        );
      },
      showSubmit() {
        return (
          this.inventory &&
          this.inventoryContext &&
          this.inventoryContext.isEditingAllowed &&
          (this.inventory.statusId === this.endInventoryStatusCode.Open ||
            this.inventory.statusId === this.endInventoryStatusCode.Rejected)
        );
      },
      inventoryStatusName() {
        let status = "";
        switch (this.inventory.statusId) {
          case this.endInventoryStatusCode.Closed:
            status = "Closed";
            break;
          case this.endInventoryStatusCode.AwaitingApproval:
            status = "Awaiting Approval";
            break;
          case this.endInventoryStatusCode.Rejected:
            status = "Reopened";
            break;
        }
        return status;
      },
      editableBgColor() {
        return this.isEditable ? "#FFF6C9" : "transparent";
      },
      customItems() {
        return this.inventory.customItems
          ? this.inventory.customItems.filter((i) => {
              return !i.isDeleted;
            })
          : null;
      },
      actionPrefix() {
        return this.isEditable ? "Editing" : "Viewing";
      },
      sortIcon() {
        return this.sortAsc ? "mdi-arrow-up" : "mdi-arrow-down";
      },
    },
    methods: {    
      updateSearchTextValue (event) {        
        this.updateSearchText(event.target.value);
      },
      updateSearchText: _debounce(function (value) {
        this.searchTouched = false;
        this.$emit('input', value);
        this.searchText = value;
        this.$emit('update:value', value);
      }, 600),  
      toggleFilter() {
        this.showFilter = !this.showFilter;
      },
      compareByColumns(a, b) {
        var nameA = a[this.sortedColumn].toUpperCase();
        var nameB = b[this.sortedColumn].toUpperCase();
        if (nameA < nameB) {
          return this.sortAsc ? -1 : 1;
        }
        if (nameA > nameB) {
          return this.sortAsc ? 1 : -1;
        }
        return 0;
      },
      compareFunction(a, b) {
        if (!a.prevQty && !b.prevQty) {
          return this.compareByColumns(a, b);
        }
        if (!a.prevQty) {
          return 1;
        }
        if (!b.prevQty) {
          return -1;
        }
        return this.compareByColumns(a, b);
      },
      sortColumn(colName) {
        this.sortAsc = !this.sortAsc;
        this.sortedColumn = colName;
        this.inventory.items.sort(this.compareFunction);
      },
      getItemDefById(itemId) {
        return this.itemLookup.find(function (i) {
          return i.itemId === itemId;
        });
      },
      isItemmAlreadyAdded(itemId) {
        return this.inventory.items.find(function (i) {
          return i.itemId === itemId;
        });
      },
      onNewItemChanged(newValue) {
        if (newValue) {
          if (this.isItemmAlreadyAdded(newValue)) {
            this.$store.dispatch(
              "alert/error",
              "Such an item is already added to the inventory"
            );
          } else {
            this.newItem.vendorId = this.getItemDefById(newValue).vendorId;
            this.inventory.items.push(this.newItem);
          }
          this.clearNewItem();
        }
      },
      onItemChanged(newValue) {
        if (newValue) {
          if (this.isItemmAlreadyAdded(newValue)) {
            this.$store.dispatch(
              "alert/error",
              "Such an item already presents in the inventory"
            );
            return false;
          }
        }
      },
      clearNewItem() {
        const that = this;
        setTimeout(function () {
          if (that.inventory) {
            that.newItem = that.$extend(true, {}, that.inventory.emptyItem);
          }
        }, 100);
      },
      reload() {
        const that = this;
        const promises = [];

        that.loading = true;
        const month = this.inventoryContext.currentInventoryMonth;
        const year = this.inventoryContext.currentInventoryYear;
        const url = this.$route.params.storeId
          ?  `/api/inventory/current-inventory/${this.$route.params.storeId}/${month}/${year}`
          : `/api/inventory/current-inventory/${this.currentStoreId}/${month}/${year}`;
        promises.push(
          http
            .get(url)
            .then(that.handleResponse)
            .then((response) => {
              if (response.message) {
                that.$store.dispatch("alert/error", response.message);
              }

              that.inventory = response.content;
              that.clearNewItem();
            })
            .catch((error) => {
              that.$store.dispatch("alert/error", error);
            })
            .finally(() => {})
        );

        /*  promises.push(http.get(`/api/item/all/${this.currentStoreId}`)
                      .then(this.handleResponse)
                      .then(response => {
                          if (response.message) {
                              that.$store.dispatch("alert/error", response.message);
                          }
                          that.itemLookup = response.content;
                      }).catch((error) => {
                          that.$store.dispatch("alert/error", error);
                      }).finally(() => {
                      }));
                      
                  promises.push(http.get(`/api/unit/all/${this.currentStoreId}`)
                      .then(this.handleResponse)
                      .then(response => {
                          if (response.message) {
                              that.$store.dispatch("alert/error", response.message);
                          }
                          that.unitLookup = response.content;
                      }).catch((error) => {
                          that.$store.dispatch("alert/error", error);
                      }).finally(() => {
                      }));
                      */
        Promise.all(promises).finally(() => {
          that.loading = false;
        });
      },
      removeCustomItem(item) {
        let i = 0;
        for (i = 0; i < this.inventory.customItems.length; i++) {
          if (
            this.inventory.customItems[i].endInventoryCustomDetailId ===
            item.endInventoryCustomDetailId
          ) {
            this.inventory.customItems[i].isDeleted = true;
          }
        }
      },
      addCustomItem() {        
        this.newCustomItem = this.$extend(true, {}, this.inventory.emptyCustomItem);                
      },
      acceptNewCustomItem() {
        if (!this.newCustomItem.description) {
          return;
        }
        let id = 0;
        if (!this.inventory.customItems) {
          this.inventory.customItems = [];
        } else {
          this.inventory.customItems.forEach((i) => {
            if (id < i.endInventoryCustomDetailId) {
              id = i.endInventoryCustomDetailId;
            }
          });
        }
        id++;  
        this.newCustomItem.endInventoryCustomDetailId = id;
        this.inventory.customItems.push(this.newCustomItem);
        this.newCustomItem = null;
      },
      cancelAddCustomItem() {        
        this.newCustomItem = null;
      },
      saveInventory(statusId) {
        if (!this.$refs.form.validate()) {
          return;
        }
        const that = this;
        this.loading = true;
        var payload = that.$extend(true, {}, that.inventory);
        if (statusId) {
          payload.statusId = statusId;
        }
        http
          .patch("/api/inventory/save-inventory", payload)
          .then(this.handleResponse)
          .then((response) => {
            if (!response.message) {
              that.$store.dispatch("alert/info", "Successfully saved");
              that.inventory = response.content;
              that.onSaved();
            } else {
              that.$store.dispatch("alert/error", response.message);
            }
          })
          .catch((error) => {
            that.$store.dispatch("alert/error", error);
          })
          .finally(() => {
            that.loading = false;
          });
      },
      goBack() {
        this.$router.push("/home");
      },
      onSaved() {
        if (
          this.isUserRole(this.roleCode.Manager) ||
          this.isUserRole(this.roleCode.SuperAdmin)
        ) {
          this.goBack();
        }
      },
      closeInventory() {
        this.saveInventory(this.endInventoryStatusCode.Closed);
      },
      submitInventory() {
        this.saveInventory(this.endInventoryStatusCode.AwaitingApproval);
      },
      rejectInventory() {
        this.saveInventory(this.endInventoryStatusCode.Rejected);
      },
    },
    mounted() {
      if (!this.inventoryContext) {
        this.$router.push("/home");
      }
      this.reload();
    },
  };
</script>
