<template>
    <v-card dense class="d-flex align-content-end flex-wrap" :loading="loading">
        <v-form autocomplete="off" ref="form" style="width:100%">
            <div class="mt-8 mb-8" v-if="!inventories">Loading..</div>
            <v-expansion-panels
                v-if="inventories && inventoryContext"                
                multiple>
                <v-expansion-panel>
                    <v-expansion-panel-header><v-card-title v-if="inventories">Pending {{currentMonthName}} Inventories ({{pendingInventories.length}})</v-card-title>            </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid v-if="inventories">
                    <v-row :style="getAlternateRowStyle(index)" no-gutters v-for="(item, index) in pendingInventories" :key="item.storeId">
                        <v-col xs="6" sm="5" md="4" lg="4" class="pr-1">
                            <label class="d-block" v-if="index === 0"><b>Inventory Number</b></label>
                            <span>{{item.endInventoryNumber}}</span>
                        </v-col>
                        <v-col xs="5" sm="5" md="4" lg="4" class="pr-1">
                            <label class="d-block" v-if="index === 0"><b>Store</b></label>
                            <span>{{item.storeDisplayName}}</span>
                        </v-col>
                        <v-col xs="1" sm="1" md="1" lg="1">
                            <label class="d-block" v-if="index === 0"><b>Actions</b></label>
                            <v-icon class="mr-1"
                                    title="Edit Inventory"
                                    @click="editInventory(item)"
                                    :disable="loading">
                                mdi-pencil
                            </v-icon>
                            <v-icon class="mr-1"
                                    title="View Previous Inventory"
                                    @click="viewPreviousInventory(item)"
                                    :disable="loading">
                                mdi-eye
                            </v-icon>
                        </v-col>
                    </v-row>
                </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header><v-card-title>Reopened {{currentMonthName}} Inventories ({{rejectedInventories.length}})</v-card-title></v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid v-if="inventories">
                    <v-row :style="getAlternateRowStyle(index)" no-gutters v-for="(item, index) in rejectedInventories" :key="item.storeId">
                        <v-col xs="6" sm="5" md="4" lg="4" class="pr-1">
                            <label class="d-block" v-if="index === 0"><b>Inventory Number</b></label>
                            <span>{{item.endInventoryNumber}}</span>
                        </v-col>
                        <v-col xs="5" sm="5" md="4" lg="4" class="pr-1">
                            <label class="d-block" v-if="index === 0"><b>Store</b></label>
                            <span>{{item.storeDisplayName}}</span>
                        </v-col>
                        <v-col xs="1" sm="1" md="1" lg="1">
                            <label class="d-block" v-if="index === 0"><b>Actions</b></label>
                            <v-icon class="mr-1"
                                    title="Edit Inventory"
                                    @click="editInventory(item)"
                                    :disable="loading">
                                mdi-pencil
                            </v-icon>
                            <v-icon class="mr-1"
                                    title="View Previous Inventory"
                                    @click="viewPreviousInventory(item)"
                                    :disable="loading">
                                mdi-eye
                            </v-icon>
                        </v-col>
                    </v-row>
                </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header><v-card-title>Approved {{currentMonthName}} (Closed) Inventories ({{approvedInventories.length}})</v-card-title></v-expansion-panel-header>
                    <v-expansion-panel-content>
                         <v-container fluid v-if="inventories">
                    <v-row :style="getAlternateRowStyle(index)" no-gutters v-for="(item, index) in approvedInventories" :key="item.storeId">
                        <v-col xs="6" sm="5" md="4" lg="4" class="pr-1">
                            <label class="d-block" v-if="index === 0"><b>Inventory Number</b></label>
                            <span>{{item.endInventoryNumber}}</span>
                        </v-col>
                        <v-col xs="5" sm="5" md="4" lg="4" class="pr-1">
                            <label class="d-block" v-if="index === 0"><b>Store</b></label>
                            <span>{{item.storeDisplayName}}</span>
                        </v-col>
                        <v-col xs="1" sm="1" md="1" lg="1">
                            <label class="d-block" v-if="index === 0"><b>Actions</b></label>
                            <v-icon class="mr-1"
                                    title="Edit Inventory"
                                    @click="editInventory(item)"
                                    :disable="loading">
                                mdi-pencil
                            </v-icon>
                            <v-icon class="mr-1"
                                    title="View Previous Inventory"
                                    @click="viewPreviousInventory(item)"
                                    :disable="loading">
                                mdi-eye
                            </v-icon>
                        </v-col>
                    </v-row>
                </v-container>
                    </v-expansion-panel-content>                        
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header><v-card-title>Open {{currentMonthName}} Inventories ({{openInventories.length}})</v-card-title></v-expansion-panel-header>
                    <v-expansion-panel-content>
                         <v-container fluid v-if="inventories">
                    <v-row :style="getAlternateRowStyle(index)" no-gutters v-for="(item, index) in openInventories" :key="item.storeId">
                        <v-col xs="6" sm="5" md="4" lg="4" class="pr-1">
                            <label class="d-block" v-if="index === 0"><b>Inventory Number</b></label>
                            <span>{{item.endInventoryNumber}}</span>
                        </v-col>
                        <v-col xs="5" sm="5" md="4" lg="4" class="pr-1">
                            <label class="d-block" v-if="index === 0"><b>Store</b></label>
                            <span>{{item.storeDisplayName}}</span>
                        </v-col>
                        <v-col xs="1" sm="1" md="1" lg="1">
                            <label class="d-block" v-if="index === 0"><b>Actions</b></label>
                            <v-icon class="mr-1"
                                    title="Edit Inventory"
                                    @click="editInventory(item)"
                                    :disable="loading">
                                mdi-pencil
                            </v-icon>
                            <v-icon class="mr-1"
                                    title="View Previous Inventory"
                                    @click="viewPreviousInventory(item)"
                                    :disable="loading">
                                mdi-eye
                            </v-icon>
                        </v-col>
                    </v-row>
                </v-container>
                    </v-expansion-panel-content>                        
                </v-expansion-panel>
            </v-expansion-panels>                                  
        </v-form>
    </v-card>
</template>
<script>
    import http from "../../http";
    export default {
        data() {
            return {
                inventories: [],
                loading: false
            };
        },
        computed: {
            currentMonthName() {
                return this.inventoryContext.currentInventoryMonthName;
            },
            pendingInventories() {
                return this.inventories.filter((i) => { return i.endInventoryStatusId === this.endInventoryStatusCode.AwaitingApproval; });
            },
            rejectedInventories() {
                return this.inventories.filter((i) => { return i.endInventoryStatusId === this.endInventoryStatusCode.Rejected; });
            },
            approvedInventories() {
                return this.inventories.filter((i) => { return i.endInventoryStatusId === this.endInventoryStatusCode.Closed; });
            },
            openInventories() {
                return this.inventories.filter((i) => { return i.endInventoryStatusId === this.endInventoryStatusCode.Open; });
            }
        },
        methods: {
            editInventory(inv) {
                this.$router.push("/edit-inventory/" + inv.storeId);
            },  
            viewPreviousInventory(inv) {
                this.$router.push("/prev-inventory/" + inv.storeId);
            },
            reload() {
                const that = this;
                const promises = [];

                that.loading = true;
                const month = this.inventoryContext.currentInventoryMonth;
                const year = this.inventoryContext.currentInventoryYear;
                promises.push(http.get(`api/inventory/manager-inventories/${month}/${year}`)
                    .then(this.handleResponse)
                    .then(response => {
                        if (response.message) {
                            that.$store.dispatch("alert/error", response.message);
                        }

                        that.inventories = response.content;
                    }).catch((error) => {
                        that.$store.dispatch("alert/error", error);
                    }).finally(() => {
                    }));

                Promise.all(promises).finally(() => {
                    that.loading = false;
                });
            },
        },
        mounted() {
            this.reload();
        }
    };
</script>
