import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import auth from "./mixins/auth";
import common from './mixins/common';
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;
Vue.mixin(auth);
Vue.mixin(common);

export default new Vue({
    router,
    store,
    vuetify,
    render: function (h) { return h(App); }
}).$mount("#app");
