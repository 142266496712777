export default {
    computed: {
        isLoggedIn() {
            return this.$store.state.authentication.status.loggedIn;
        },
        isLoggingIn() {
            return this.$store.state.authentication.status.loggingIn;
        },
        currentUser() {
            return this.isLoggedIn && this.$store.state.authentication.user;
        },        
        currentUserId() {
            return this.isLoggedIn && this.$store.state.authentication.user ? this.$store.state.authentication.user.userId : "";
        },
        currentStoreId() {
            return this.isLoggedIn && this.$store.state.authentication.user ? this.$store.state.authentication.user.userContext.storeId : 0;
        },
        currentConceptId() {
            return this.isLoggedIn && this.$store.state.authentication.user ? this.$store.state.authentication.user.userContext.conceptId : 0;
        },
        currentRoleId() {
            return this.isLoggedIn && this.$store.state.authentication.user ? this.$store.state.authentication.user.userContext.roleId : 0;
        },        
        isMultipleStores() {
            return this.isLoggedIn && this.$store.state.authentication.user ? this.$store.state.authentication.user.userContext.isMultipleStores : false;
        },             
    },
    methods: {
        isUserRole(roleId) {
            return this.isLoggedIn && this.$store.state.authentication.user && this.$store.state.authentication.user.userContext.roleId === roleId;
        },          
    }
};
