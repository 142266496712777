import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store/index";
import Login from "@/views/public/login";
import Home from "@/views/home/Home";
import EditInventory from "@/views/inventory/EditInventory";
import ViewPrevInventory from "@/views/inventory/ViewPrevInventory";
import UsersList from "@/views/user/UsersList";
import StoreRoleSelector from "@/views/user/StoreRoleSelector";
import ChangePassword from "@/views/user/ChangePassword";

Vue.use(VueRouter);

const publicPages = ["/login"];

const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login
    },
    {
        path: "/home",
        name: "Home",
        component: Home
    },
    {
        path: "/edit-inventory",
        name: "EditMyCurrentInventory",
        component: EditInventory
    },
    {
        path: "/edit-inventory/:storeId",
        name: "EditCurrentInventory",        
        component: EditInventory
    },
    {
        path: "/prev-inventory",
        name: "ViewMyPrevInventory",
        component: ViewPrevInventory
    },
    {
        path: "/prev-inventory/:storeId",
        name: "ViewPrevInventory",
        component: ViewPrevInventory
    },
    {
        path: "/users",
        name: "UsersList",
        component: UsersList
    },
    {
        path: "/store-role-selector",
        name: "StoreRoleSelector",
        component: StoreRoleSelector
    },
    {
        path: "*",
        redirect: "/login"
    },
    {
        path: "/changepassword",
        name: "ChangePassword",
        component: ChangePassword
    }
];

const router = new VueRouter({
    routes: routes
});

router.beforeEach((to, from, next) => {
    store.dispatch("closeLeftDrawer");    
    const authRequired = !publicPages.includes(to.path);
    const user = store.state.authentication.user;
    const loggedIn = user && user.token;

    if (location.protocol !== 'https:'&& location.host.includes("inventory.doublepcorp.com")) {
        location.replace(`https:${location.href.substring(location.protocol.length)}`);
    }

    if (authRequired && !loggedIn) {
        return next("/login");
    }        
    return next();
});

export default router;
