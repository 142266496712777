<template>
    <v-app id="inspire" light>        
        <v-navigation-drawer disable-resize-watcher
                             v-if="leftDrawer"
                             left
                             app>
            <v-list dense>
                <v-list-item-group color="primary">                    
                    <v-list-item link v-if="isLoggedIn" :to="'/home'" :key="'home'">
                        <v-list-item-action>
                            <v-icon>mdi-home-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                Home
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link v-if="isMultipleStores" :to="'/store-role-selector'" :key="'StoreRoleSelector'">
                        <v-list-item-action>
                            <v-icon>mdi-window-restore</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                Select Dashboard
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link v-if="!isLoggedIn" :to="'/login'">
                        <v-list-item-action>
                            <v-icon>mdi-login</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                Log In
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link v-if="isLoggedIn" v-on:click="logOut()">
                        <v-list-item-action>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                Log Out
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
        <v-navigation-drawer disable-resize-watcher
                             v-if="isLoggedIn"
                             v-model="rightDrawer"
                             right
                             app>
            <v-list dense>
                <v-list-item-group color="primary">
                    <v-subheader v-if="isLoggedIn">NOTES</v-subheader>
                    <v-list-item>
                        <v-list-item-content>                            
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app color="primary" dark class="d-print-none">
            <v-app-bar-nav-icon class="" @click.stop="toggleLeftDrawer()">
                <slot name="default">
                    <v-icon>mdi-{{leftDrawer ? 'menu-open' : 'menu'}}</v-icon>
                </slot>
            </v-app-bar-nav-icon>
            <v-toolbar-title>
                <span class="d-none d-sm-inline-block">DOUBLEP INVENTORY<span v-if="isLoggedIn && isUserRole(roleCode.User)"> - </span></span><span v-if="isLoggedIn && isUserRole(roleCode.User)">{{currentUser.userContext.storeFullName}}<span class="d-none d-md-inline-block"> - {{currentUser.userContext.conceptFullName}}</span></span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <user-info :mode="'inline'" />
        </v-app-bar>

        <v-main>
            <v-container fluid>
                <keep-alive :include="keepAlivePages">
                    <router-view></router-view>
                </keep-alive>
            </v-container>
        </v-main>

        <v-footer color="primary"
                  app
                  dark>
            <span class="text-caption font-italic">DOUBLEP INVENTORY</span>
            <v-spacer></v-spacer>                                    
        </v-footer>
        <v-snackbar v-model="snackbarShowing"
                    multi-line
                    vertical>
            {{ snackbarMessage }}

            <template v-slot:action="{ attrs }">
                <v-btn :color="'secondary'"
                       text
                       v-bind="attrs"
                       @click="hideSnackbar()">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-app>
</template>

<script>
    import UserInfo from "./components/auth/UserInfo";
    //import Auth from './mixins/auth';
    //import Common from './mixins/common';

    export default {
        name: "app",
        //mixins: [ Auth, Common ],
        components: {
            UserInfo
        },
        data: () => ({            
            rightDrawer: false,
            snackbarShowing: false,            
            keepAlivePages: []
        }),
        watch: {
            snackbarMessage(newValue) {
                this.snackbarShowing = !!newValue;
            }
        },
        computed: {
            leftDrawer() {
                return this.$store.state.leftDrawer;
            },
            snackbarMessage() {
                return this.$store.getters["alert/message"];
            },
            snackbarType() {
                return this.$store.getters["alert/messageType"];
            },
        },        
        methods: {
            toggleLeftDrawer() {
                if (this.leftDrawer) {
                    this.$store.dispatch("closeLeftDrawer"); 
                } else {
                    this.$store.dispatch("openLeftDrawer"); 
                }
            },
            logOut() {
                this.$store.dispatch("authentication/logout", { root: true });
            },   
            hideSnackbar() {
                this.snackbarShowing = false;
            },
            toggleRightDrawer() {
                this.rightDrawer = !this.rightDrawer;
            },
            reload() {                   
            }
        },
        mounted() {
            this.$root.$on("toggle-right-drawer", this.toggleRightDrawer);            
            this.reload();
        },
        beforeDestroy() {
            this.$root.$off("toggle-right-drawer", this.toggleRightDrawer);
        }
    };
</script>
