import Vue from "vue";
import Vuex from "vuex";
import { alert } from "./alert.module";
import { authentication } from "./authentication.module";
import { users } from "./users.module";

Vue.use(Vuex);

function initialState() {
    return {
        inventoryContext: null,
        leftDrawer: false
    };
}

export default new Vuex.Store({
    state: initialState,
    modules: {
        alert,
        authentication,        
        users
    },
    getters: {
    },
    mutations: {
        setInventoryContextMutation(state, inventoryContext) {
            state.inventoryContext = inventoryContext;            
        },
        setLeftDrawerMutation(state, leftDrawer) {
            state.leftDrawer = leftDrawer;            
        },
    },
    actions: {
        setInventoryContext({ commit }, { inventoryContext }) {
            commit("setInventoryContextMutation", inventoryContext);            
        },    
        closeLeftDrawer({ commit }) {
            commit("setLeftDrawerMutation", false);            
        },  
        openLeftDrawer({ commit }) {
            commit("setLeftDrawerMutation", true);            
        },         
    }
});
