import { userService } from "../services/user.service";
import router from "../router";

let user = null;

const userString = window.sessionStorage.getItem("dpi_user");

if (userString && userString !== "undefined" && userString !== "[object Object]") {
    user = JSON.parse(userString);
}

const initialState = user
    ? { status: { loggedIn: true }, user: user }
    : { status: {}, user: null };

export const authentication = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ dispatch, commit }, { username, password }) {
            commit("loginRequest", { username });
            const that = this;
            userService.login(username, password).then(function (user) {                
                commit("loginSuccess", user);
                if (user.forcePasswordChange) {
                    router.push("/changepassword").catch(() => { });
                } else {
                    dispatch("alert/info", "Successful login.", { root: true });                       
                    if (that.state.authentication.user.userContext.isMultipleStores) {                             
                        router.push("/store-role-selector").catch(() => { });
                    } else {
                        router.push("/home").catch(() => { });
                    }
                }
            }, function (error) {
                console.log("authentication.module: error", error);
                commit("loginFailure", error);
                dispatch("alert/warning", "The username and/or password you entered " +
                    "does not match our records. Please try again.", { root: true });
            });
        },
        logout({ dispatch, commit }) {
            userService.logout();
            commit("logout");
            dispatch("alert/info", "You have signed out.", { root: true });
            router.push("/login").catch(() => { });
        },
        update({ commit }, { updatedUser }) {
            commit("updateSuccess", updatedUser);
        },
        setCurrentStoreRole({ commit }, { storeRole }) {
            commit("setCurrentStoreRoleMutation", storeRole);            
        },        
    },
    mutations: {
        loginRequest(state, model) {
            state.status = { loggingIn: true };
            state.user = model;
        },
        loginSuccess(state, model) {            
            window.sessionStorage.setItem("dpi_user", JSON.stringify(model));
            state.status = { loggedIn: true };
            state.user = model;
        },
        loginFailure(state) {
            state.status = {};
            state.user = null;
        },
        logout(state) {
            state.status = {};
            state.user = null;
        },
        updateSuccess(state, model) {
            window.sessionStorage.setItem("dpi_user", JSON.stringify(model));
            state.user = model;
        },
        setCurrentStoreRoleMutation(state, storeRole) {
            state.user.userContext.storeId = storeRole.storeId;
            state.user.userContext.roleId = storeRole.roleId;
            state.user.userContext.conceptId = storeRole.conceptId;
            state.user.userContext.storeLocation = storeRole.storeLocation;
            state.user.userContext.conceptName = storeRole.conceptName;
            state.user.userContext.conceptFullName = storeRole.conceptFullName;
            state.user.userContext.pollingNumber = storeRole.pollingNumber;
            state.user.userContext.storeFullName = storeRole.pollingNumber + " " + storeRole.storeLocation;
            window.sessionStorage.setItem("dpi_user", JSON.stringify(state.user));
        },
    }
};
