export default {
    data() {
        return {
            emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            passwordComplexityRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            ssnRegex: /^\d{9}$/,
            toastTime: 5000,
            formValidationRules: {
                required: value => !!value || value === 0 || "Required.",
                int: value => (!isNaN(value) && Number.isInteger(Number(value))) || "Integer value expected.",
                email: value => {
                    return this.emailRegex.test(value) || "Invalid e-mail.";
                },
                ssn: value => {
                    return this.ssnRegex.test(value) || "Invalid SSN. Please enter numbers only.";
                },
                passwordComplexity: value => {
                    return this.passwordComplexityRegex.test(value) || "Min. 8 characters with at least one capital letter, one number, and one special character.";
                }
            },
            endInventoryStatusCode: {
                Open: 1,
                Closed: 2,
                AwaitingApproval: 3,
                Rejected: 4
            },
            roleCode: {
                Unknown: 0,
                SuperAdmin: 1,
                Manager: 2,
                User: 3
            }
        };
    },
    computed: {
        inventoryContext() {
            return this.$store.state.inventoryContext;
        },  
    },
    methods: {
        handleResponse(response) {
            const data = response.data;
            
            if (response.status !== 200) {
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            
            return data;
        },
        formatDate(dateValue) {
            if (dateValue) {
                if (typeof dateValue === "object" || dateValue instanceof Date) {
                    return dateValue.toLocaleDateString("en-US",
                        {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric"
                        });
                } else {
                    if (dateValue.length >= 10) {
                        return new Date(dateValue).toLocaleDateString("en-US",
                            {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric"
                            });
                    }
                    if (typeof dateValue.substring === "function") {
                        return dateValue.substring(0, 10);
                    }
                }
            }

            return dateValue;
        },
        isOdd(num) {
            return num % 2;
        },
        getAlternateRowStyle(index) {
            return "background-color: " + (this.isOdd(index) ? "#EEEAF3" : "white");
        },
        $extend() {

            // Variables
            var extended = {};
            var deep = false;
            var i = 0;
            var length = arguments.length;
        
            // Check if a deep merge
            if ( Object.prototype.toString.call( arguments[0] ) === '[object Boolean]' ) {
                deep = arguments[0];
                i++;
            }
        
            const that = this;
            // Merge the object into the extended object
            var merge = function (obj) {
                for ( var prop in obj ) {
                    if ( Object.prototype.hasOwnProperty.call( obj, prop ) ) {
                        // If deep merge and property is an object, merge properties
                        if ( deep && Object.prototype.toString.call(obj[prop]) === '[object Object]' ) {
                            extended[prop] = that.$extend( true, extended[prop], obj[prop] );
                        } else {
                            extended[prop] = obj[prop];
                        }
                    }
                }
            };
        
            // Loop through each object and conduct a merge
            for ( ; i < length; i++ ) {
                var obj = arguments[i];
                merge(obj);
            }
        
            return extended;
        
        }
    }
};
