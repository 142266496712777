<template>
  <span class="d-flex justify-center">
    <v-card
      raised
      :loading="!storeRoles"
      :max-width="'40rem'"
      :min-width="'20rem'"
    >
      <v-card-title>Dashboard Selector</v-card-title>
      <v-card-text>
        <v-form>
          <v-row>
            <v-col>
                <v-select
                    v-if="storeRoles"
                    v-model="selectedItem"
                    :items="storeRoles"
                    label="Select the Dashboard">
                    <template v-slot:selection="{ item }">                    
                        <span v-if="item.roleId === roleCode.Manager">District Manager</span>
                        <span v-if="item.roleId === roleCode.User">Store #{{ item.pollingNumber }} {{ item.storeLocation }} ({{ item.conceptFullName }})</span>
                    </template>
                    <template v-slot:item="{ item }">                    
                        <span v-if="item.roleId === roleCode.Manager">District Manager</span>
                        <span v-if="item.roleId === roleCode.User">Store #{{ item.pollingNumber }} {{ item.storeLocation }} ({{ item.conceptFullName }})</span>
                    </template>
                </v-select>
            </v-col>
          </v-row>            
          <v-row>
            <v-col>
              <v-btn               
                color="secondary"
                :disabled="!selectedItem"
                title="Apply"
                @click="changeStoreRole()">
                Apply</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </span>
</template>
<script>
import http from "../../http";
import router from "../../router";

export default {
        name: "ChangePassword",
        data() {
            return {
                storeRoles: null,
                selectedItem: null                
            };
        },
        methods: {         
            changeStoreRole() {
                if (!this.selectedItem) {
                    return;
                }
                this.$store.dispatch("authentication/setCurrentStoreRole", { storeRole: this.selectedItem } );
                router.push("/home").catch(() => { });                
            },   
            init() {
                if (this.isLoggedIn && this.$store.state.authentication.user) {                    
                    const that = this;
                    var matches = this.storeRoles.filter((r) => {
                        return r.roleId === that.currentRoleId && r.storeId === that.currentStoreId;
                    });
                    if (matches && matches.length) {
                        this.selectedItem = matches[0];
                    }                    
                }
                if (!this.selectedItem && this.storeRoles.length) {
                    this.selectedItem = this.storeRoles[0];
                }                
            },
            fetchStoreRoles() {
                const that = this;
                http
                    .get("api/user/store-roles")
                    .then(this.handleResponse)
                    .then((response) => {
                        if (!response.message) {
                            that.storeRoles = response.content;
                            that.init();
                        } else {
                            that.store.dispatch("alert/error", response.message);
                        }
                    })
                        .catch((error) => {
                            that.store.dispatch("alert/error", error);
                        });
            }       
        },
         mounted() {
            this.fetchStoreRoles();            
        },
};
</script>
