<template>
    <div>
        <span class="d-flex justify-center" v-if="isLoggedIn && isUserRole(roleCode.User)">
            <v-card raised
                    :loading="!inventoryContext"
                    max-width="40rem"
                    min-width="20rem">
                <v-card-text class="justify-center">
                    <v-list-item>
                        <v-list-item-content>
                            <v-btn dense color="primary" title="View Previous Inventory" @click="viewPreviousInventory()">
                                <v-icon>mdi-receipt</v-icon>
                                <span class="ml-2">View {{inventoryContext.previousInventoryMonthName}} Inventory</span>
                            </v-btn>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-btn dense color="primary"
                                   title="Edit Current Inventory"
                                   @click="editCurrentInventory()"
                                   :loading="!inventoryContext"
                                   :disabled="!inventoryContext || !inventoryContext.isEditingAllowed">
                                <v-icon>mdi-pencil-box-outline</v-icon>
                                <span class="ml-2">Edit {{inventoryContext.currentInventoryMonthName}} Inventory</span>
                            </v-btn>
                        </v-list-item-content>
                    </v-list-item>                  
                </v-card-text>
            </v-card>
        </span>
        <manager-inventories v-if="isLoggedIn && isUserRole(roleCode.Manager) && inventoryContext" />
        <span class="d-flex justify-center" v-if="isLoggedIn && isUserRole(roleCode.SuperAdmin)">
            <v-card raised
                    max-width="40rem"
                    min-width="20rem">
                <v-card-text class="justify-center">
                    <v-list-item>                      
                     <v-list-item>
                        <v-list-item-content>
                            <v-btn dense color="primary"
                                   title="View Users"
                                   @click="viewUsers()"
                                   :loading="!inventoryContext">
                                <v-icon>mdi-pencil-box-outline</v-icon>
                                <span class="ml-2">View Users</span>
                            </v-btn>
                        </v-list-item-content>                    
                    </v-list-item>                    
                    </v-list-item>
                </v-card-text>
            </v-card>
        </span>
    </div>
</template>
<script>

import ManagerInventories from "@/components/inventory/ManagerInventories";
import http from "../../http";

    export default {
        name: "home",
        data() {
            return {                                                
            };
        },
        components: {
            ManagerInventories
        },
        computed: {            
        },
        methods: {
            editCurrentInventory() {
                this.$router.push("/edit-inventory");
            },  
            viewPreviousInventory() {
                this.$router.push("/prev-inventory");
            },
            viewUsers() {
                this.$router.push("/users");
            },
            fetchInventoryContext() {
                const that = this;
                http
                    .get("api/inventory/context")
                    .then(this.handleResponse)
                    .then((response) => {
                        if (!response.message) {
                            const context = response.content;     
                            this.$store.dispatch("setInventoryContext", { inventoryContext: context } );                       
                        } else {
                            that.$store.dispatch("alert/error", response.message);
                        }
                    })
                        .catch((error) => {
                            that.$store.dispatch("alert/error", error);
                        });
            }   
        },
        mounted() {     
            this.fetchInventoryContext();       
        }
};
</script>
