import http from "axios";
import store from "./store/index";

http.defaults.timeout = 300000;
http.defaults.timeoutErrorMessage = "Sorry, the last action took too long to respond. Please try again.";

http.interceptors.response.use((response) => {
      return response;
    }, (error) => {
    const messageContainer = getErrorContainer(error);

    if (error && error.response && error.response.status) {
        let showMessage = true;

        switch (error.response.status) {
            case 400:
                alert(`An unexpected error occurred: ${error.response.message}`);
                break;
            case 403:
            case 401:
                try {
                  if (error.response.config && error.response.config.data) {
                    const requestPayload = JSON.parse(error.response.config.data);

                    if (requestPayload && requestPayload.item && requestPayload.item === "Unknown") {
                      showMessage = false;
                    }
                  }
                } catch (err) {
                  console.log("request parsing error in 401 block", err);
                }

                if (showMessage) {
                  alert("Sorry, it seems your account is not authorized to access that feature.");
                }
                break;
            case 406:
                // login failed
                break;
            default:
                if (messageContainer.responseMessage) {
                    alert(messageContainer.responseMessage);             
                } else {
                    alert(messageContainer.httpMessage);
                }
                break;
        }
    }

    return Promise.reject(error);
});

function getErrorContainer(error) {
    const result = {
        httpMessage: null,
        responseMessage: null
    };

    if (error && error.message) {
        result.httpMessage = error.message;
    }

    if (error && error.response && error.response.data && error.response.data.error) {
        result.responseMessage = error.response.data.error;
    }

    return result;
}

export default {
    baseUrl: "",
    //baseUrl: "https://localhost:44310/",
    getErrorContainer: getErrorContainer,
    getFullUrl(relativePath) {
        return this.baseUrl + relativePath;
    },
    setBearer: function() {
        http.defaults.headers.common.Authorization = this.getBearer();
    },
    getBearer: function() {
        const user = store.state.authentication.user;        
        return "Bearer " + (user ? user.token : "(Unknown)");
    },
    patch: function (url, model) {
        http.defaults.headers.common["Content-Type"] = "application/json";
        this.setBearer();
        return http.patch(this.getFullUrl(url), model);
    },
    post: function (url, model, config) {
        http.defaults.headers.common["Content-Type"] = "application/json";
        this.setBearer();
        return http.post(this.getFullUrl(url), model, config);
    },
    postFormData: function (url, model, config) {
        http.defaults.headers.common["Content-Type"] = "multipart/form-data";
        this.setBearer();
        return http.post(this.getFullUrl(url), model, config);
    },
    put: function (url, model) {
        http.defaults.headers.common["Content-Type"] = "application/json";
        this.setBearer();
        return http.put(this.getFullUrl(url), model);
    },
    get: function (url, config) {
        this.setBearer();
        return http.get(this.getFullUrl(url), config);
    },
    delete: function (url) {
        this.setBearer();
        return http.delete(this.getFullUrl(url));
    }
};
