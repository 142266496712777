const messageTimeout = 12000;

export const alert = {
    namespaced: true,
    state: {
        type: null,
        message: null
    },
    actions: {
        success({ commit }, message) {
            commit("success", message);

            window.setTimeout(function () {
                commit("clear");
            }, messageTimeout);
        },
        error({ commit }, message) {
            commit("error", message);

            window.setTimeout(function () {
                commit("clear");
            }, messageTimeout);
        },
        errorNoClear({ commit }, message) {
            commit("error", message);
        },
        warning({ commit }, message) {
            commit("warning", message);

            window.setTimeout(function () {
                commit("clear");
            }, messageTimeout);
        },
        info({ commit }, message) {
            commit("info", message);

            window.setTimeout(function () {
                commit("clear");
            }, messageTimeout);
        },
        timedInfo({ commit }, { message, keepAlive }) {
            commit("info", message);

            window.setTimeout(function () {
                commit("clear");
            }, keepAlive ? keepAlive : messageTimeout);
        },
        clear({ commit }) {
            commit("clear");
        }
    },
    getters: {
        message(state) {
            return state.message;
        },
        messageType(state) {
            return state.type;
        }
    },
    mutations: {
        success(state, message) {
            state.type = "alert-success";
            state.message = message;
        },
        error(state, message) {
            state.type = "alert-danger";
            state.message = message;
        },
        warning(state, message) {
            state.type = "alert-warning";
            state.message = message;
        },
        info(state, message, keepAlive) {
            state.type = "alert-info";
            state.message = message;
            state.keepAlive = keepAlive;
        },
        clear(state) {
            state.type = null;
            state.message = null;
        }
    }
};
