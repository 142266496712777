<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="users"
      disable-pagination
      :hide-default-footer="true"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Users</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialogCreateEdit" max-width="500px">
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                @click="showCreateUserDlg()"
                v-on="on"
                >Create User</v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.username"
                        label="Username"
                        :rules="[formValidationRules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.email"
                        label="Email"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.firstName"
                        label="First name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.lastName"
                        label="Last name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" v-if="editedIndex === -1">
                      <v-text-field
                        v-model="editedItem.password"
                        label="Password"
                        :rules="[formValidationRules.required]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeCreateEdit"
                  >Cancel</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="saveCreateEdit"
                  :disabled="!formIsValid"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this user?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Yes</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogAssignUserToStore" max-width="650px">
            <v-card>
              <v-card-title class="text-h5"
                >Assign {{ editedItem.username }} to Store</v-card-title
              >
              <v-container fluid>
                <v-row align="center">
                  <v-col cols="3">
                    <v-combobox
                      v-model="selectedRole"
                      :items="roles"
                      label="Select role"
                      item-text="roleName"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="7">
                    <v-combobox
                      v-model="selectedStore"
                      :items="stores"
                      label="Select store"
                    >
                      <template slot="selection" slot-scope="{ item }">
                        {{ item.pollingNumber }} {{ item.storeLocation }}
                        {{ item.conceptName }}
                      </template>
                      <template slot="item" slot-scope="{ item }">
                        {{ item.pollingNumber }} {{ item.storeLocation }}
                        {{ item.conceptName }}
                      </template>
                    </v-combobox>
                  </v-col>
                  <v-col cols="2">
                    <v-btn color="blue darken-1" text @click="assignUser"
                      >Assign</v-btn
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      :headers="userStoreHeaders"
                      :items="userStores"
                      disable-pagination
                      :hide-default-footer="true"
                      class="scrollableGrid elevation-1"
                    >
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-icon class="mr-2" @click="dismissUser(item)">
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeAssignUser"
                  >Close</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogResetUserPassword" max-width="300px">
            <v-card>
              <v-card-title class="text-h5">Reset User Password</v-card-title>
              <v-container fluid>
                <v-row align="center">
                  <v-col cols="8">
                    <v-text-field
                      v-model="editedItem.password"
                      label="Password"
                      :rules="[formValidationRules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="resetUserPassword()"
                      :disabled="!formResetUserPasswordIsValid"
                      >Reset</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="closeResetUserPassword()"
                  >Close</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon class="mr-2" title="Edit User" @click="showEditUserDlg(item)">
          mdi-pencil
        </v-icon>
        <v-icon
          class="mr-2"
          title="Delete User"
          @click="showDeleteUserDlg(item)"
        >
          mdi-delete
        </v-icon>
        <v-icon
          class="mr-2"
          title="Assign User to Store"
          @click="showAssignUserToStoreDlg(item)"
          >mdi-bank-plus</v-icon
        >
        <v-icon
          class="mr-2"
          title="Reset Password"
          @click="showResetUserPasswordDlg(item)"
          >mdi-shield-check</v-icon
        >
      </template>
    </v-data-table>
  </div>
</template>
<style>
tbody tr:nth-of-type(odd) {
  background-color: #eeeaf3;
}
th[role="columnheader"] span {
  font-weight: bold;
  color: black;
}
.scrollableGrid.elevation-1 {
  height: 200px;
  overflow: auto;
}
</style>
<script>
import http from "../../http";

export default {
  data: function () {
    return {
      dialogCreateEdit: false,
      dialogDelete: false,
      dialogAssignUserToStore: false,
      dialogResetUserPassword: false,
      editedIndex: -1,
      headers: [
        { text: "Username", value: "username" },
        { text: "Person Name", value: "fullName" },
        { text: "Store Assignments", value: "storeAssignments" },                
        { text: "Actions", value: "actions", sortable: false },
      ],
      userStoreHeaders: [
        { text: "Store", value: "store" },
        { text: "Role", value: "role" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      users: [],
      roles: [],
      stores: [],
      userStores: [],
      selectedRole: null,
      selectedStore: null,
      editedItem: {
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        password: "",
      },
      defaultItem: {
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        password: "",
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit existing User";
    },
    formIsValid () {
      return (
        this.editedItem.username &&
        (this.editedIndex > -1 || this.editedItem.password)
      )
    },
    formResetUserPasswordIsValid() {
      return this.editedItem.password
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchUsers();
      },
      deep: true,
    },
  },
  methods: {
    fetchUsers() {
      const that = this;
      http
        .get("api/user/all")
        .then(this.handleResponse)
        .then((response) => {
          if (!response.message) {
            that.users = response.content;
          } else {
            that.$store.dispatch("alert/error", response.message);
          }
        })
        .catch((error) => {
          that.$store.dispatch("alert/error", error);
        });
    },
    fetchRoles() {
      const that = this;
      http
        .get("api/role/all")
        .then(this.handleResponse)
        .then((response) => {
          if (!response.message) {
            that.roles = response.content.filter((r) => {
              return r.roleId !== 1;
            });
          } else {
            that.$store.dispatch("alert/error", response.message);
          }
        })
        .catch((error) => {
          that.$store.dispatch("alert/error", error);
        });
    },
    fetchStores() {
      const that = this;
      http
        .get("api/store/all")
        .then(this.handleResponse)
        .then((response) => {
          if (!response.message) {
            that.stores = response.content;
          } else {
            that.$store.dispatch("alert/error", response.message);
          }
        })
        .catch((error) => {
          that.store.dispatch("alert/error", error);
        });
    },
    showCreateUserDlg() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem.password = this.generatePassword(6);
      this.dialogCreateEdit = true;
    },
    showEditUserDlg(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogCreateEdit = true;
    },
    showDeleteUserDlg(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    showAssignUserToStoreDlg(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.reloadUserStores();
      this.dialogAssignUserToStore = true;
    },
    showResetUserPasswordDlg(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.password = this.generatePassword(6);
      this.dialogResetUserPassword = true;
    },
    deleteItemConfirm() {   
      const that = this;         
      http.delete("/api/user/delete/" + this.users[this.editedIndex].userId)
        .then(that.handleResponse)
        .then(response => {
            if (!response.message) {
                that.$store.dispatch("alert/info", "Successful delete");                
                that.users.splice(that.editedIndex, 1);                             
            } else {
                that.$store.dispatch("alert/error", response.message);
            }

            return response.content;
        }).catch((error) => {
            that.$store.dispatch("alert/error", error);
        }).finally(() => {
          that.closeDelete();
        });            
    },
    closeCreateEdit() {
      this.dialogCreateEdit = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    saveCreateEdit() {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedItem);
        this.updateUser(this.users[this.editedIndex]);
      } else {        
        this.createUser(this.editedItem);
      }      
    },
    createUser(user) {
      const that = this;
      http.post("/api/user/register", user)
        .then(that.handleResponse)
        .then(response => {
            if (!response.message) {
                that.$store.dispatch("alert/info", "Success");
                that.closeCreateEdit();
                that.fetchUsers();
            } else {
                that.$store.dispatch("alert/error", response.message);
            }            
        }).catch((error) => {
            that.$store.dispatch("alert/error", error);
        }).finally(() => {
        });
    },
    updateUser(user) {
      const that = this;
      http.put("/api/user/update", user)
        .then(that.handleResponse)
        .then(response => {
            if (!response.message) {
                that.$store.dispatch("alert/info", "Success");
                that.closeCreateEdit();
                that.fetchUsers();
            } else {
                that.$store.dispatch("alert/error", response.message);
            }            
        }).catch((error) => {
            that.$store.dispatch("alert/error", error);
        }).finally(() => {
        });
    },   
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeAssignUser() {
      this.dialogAssignUserToStore = false;
      this.fetchUsers();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    assignUser() {
      if (!this.selectedRole) {
        this.$store.dispatch("alert/error", "Please select Role");
        return;
      }
      if (!this.selectedStore) {
        this.$store.dispatch("alert/error", "Please select Store");
        return;
      }      
      const that = this;
      if (
        this.editedItem.userStores.findIndex(
          (x) => x.storeId == this.selectedStore.storeId
        ) != -1
      ) {
        that.$store.dispatch(
          "alert/error",
          "User is already assigned to this store"
        );
      }
      else
      {
        http.post(
            "api/user/" +
              this.editedItem.userId +
              "/store/" +
              this.selectedStore.storeId +
              "/role/" +
              this.selectedRole.roleId
          )
          .then(this.handleResponse)
          .then((response) => {
            if (!response.message) {
              that.editedItem.userStores.push({
                storeId: that.selectedStore.storeId,
                roleId: that.selectedRole.roleId,
              });
              that.reloadUserStores();
            } else {
              that.$store.dispatch("alert/error", response.message);
            }
          })
          .catch((error) => {
            that.$store.dispatch("alert/error", error);
        });
      }
    },
    dismissUser(item) {
      const that = this;
      http
        .delete(
          "api/user/" +
            this.editedItem.userId +
            "/store/" +
            item.storeId +
            "/role/" +
            item.roleId
        )
        .then(this.handleResponse)
        .then((response) => {
          if (!response.message) {
            that.editedItem.userStores.splice(
              that.editedItem.userStores.findIndex(
                (x) => x.roleId == item.roleId && x.storeId == item.storeId
              ),
              1
            );
            that.reloadUserStores();
          } else {
            that.$store.dispatch("alert/error", response.message);
          }
        })
        .catch((error) => {
          that.$store.dispatch("alert/error", error);
        });
    },
    closeResetUserPassword() {
      this.dialogResetUserPassword = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    resetUserPassword() {
      const that = this;
      var model = {password:this.editedItem.password}
      http.post("api/user/"+this.editedItem.userId+"/reset-password", model)
        .then(this.handleResponse)
        .then((response) => {
          if (!response.message) {
            this.closeResetUserPassword();
          } else {
            that.$store.dispatch("alert/error", response.message);
          }
        })
        .catch((error) => {
          that.$store.dispatch("alert/error", error);
      });
    },
    reloadUserStores() {
      this.userStores = [];
      this.editedItem.userStores.forEach((userStore) => {
        var store = this.stores.find(function (store) {
          if (store.storeId == userStore.storeId) return true;
        });
        var role = this.roles.find(function (role) {
          if (role.roleId == userStore.roleId) return true;
        });

        this.userStores.push({
          storeId: store.storeId,
          store:
            store.pollingNumber +
            " " +
            store.storeLocation +
            " " +
            store.conceptName,
          roleId: role.roleId,
          role: role.roleName,
        });
      });
    },
    generatePassword(passwordLength) {
      var numberChars = "0123456789";
      var upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var lowerChars = "abcdefghiklmnopqrstuvwxyz";
      var allChars = numberChars + upperChars + lowerChars;
      var randPasswordArray = Array(passwordLength);
      randPasswordArray[0] = numberChars;
      randPasswordArray[1] = upperChars;
      randPasswordArray[2] = lowerChars;
      randPasswordArray = randPasswordArray.fill(allChars, 3);
      return this.shuffleArray(
        randPasswordArray.map(function (x) {
          return x[Math.floor(Math.random() * x.length)];
        })
      ).join("");
    },
    shuffleArray(array) {
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    },
  },
  mounted() {
    this.fetchUsers();
    this.fetchRoles();
    this.fetchStores();
  },
};
</script>
