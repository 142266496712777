<template>
    <span class="d-flex justify-center">        
        <v-card raised
                :loading="isLoggingIn"
                :max-width="'40rem'"
                :min-width="'20rem'">            
            <v-card-title>Log In</v-card-title>
            <v-card-subtitle class="text-left">Enter your username and password</v-card-subtitle>
            <v-card-text>
                <v-form v-model="isLoginFormValid">
                    <v-row>
                        <v-col>
                            <v-text-field placeholder="User Name"
                                          :rules="[formValidationRules.required]"
                                          dense
                                          class=""
                                          v-model="username"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field placeholder="Password"
                                          :rules="[formValidationRules.required]"
                                          :type="revealPassword ? 'text' : 'password'"
                                          class=""
                                          v-model="password"
                                          v-on:keyup.enter="passwordEnterKeyUp()"
                                          dense />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn :loading="isLoggingIn"
                                   :disabled="loginButtonDisabled"
                                   color="secondary"
                                   class=""
                                   title="Log in"
                                   @click="logIn()">Log In</v-btn>
                        </v-col>                      
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </span>
</template>
<script>
//import Auth from '../../mixins/auth';
//import Common from '../../mixins/common';
export default {
        name: "login",
        //mixins: [ Auth,Common ],
        data() {
            return {
                submitted: false,
                username: null,
                password: null,
                revealPassword: false,
                isLoginFormValid: false                
            };
        },
        computed: {
            loginButtonDisabled() {
                return !this.isLoginFormValid;
            }
        },
        methods: {
            toggleNotes() {
                this.$root.$emit("toggle-right-drawer");
            },
            passwordEnterKeyUp() {
                if (!this.loginButtonDisabled && !this.isLoggingIn) {
                    this.logIn();
                }
            },
            logIn() {
                this.submitted = true;
                const { username, password } = this;

                if (username && password) {
                    this.$store.dispatch("authentication/login", { username, password });
                }
            },
            logOut() {
                this.$store.dispatch("authentication/logout", { root: true });
            },
            togglePasswordVisibility: function () {
                const inputType = document.querySelector("#password").getAttribute("type");
                document.querySelector("#password").setAttribute("type", inputType === "text" ? "password" : "text");
            },
        }
};
</script>
