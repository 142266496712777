import http from "../http";
import store from "../store/index";

export const userService = {
    login,
    logout    
};

function login(username, password) {
    const model = {
        "Username": username,
        "Password": password,
        "RememberMe": true
    };

    return http.post("/api/user/authenticate", model)
        .then(handleResponse)
        .then(response => {
            if (!response.message) {
                return response.content;
            }

            store.dispatch("alert/error", response.message);
            return response.message;
        }).catch((error) => {
            console.log("authenticate error", error);
            store.dispatch("alert/error", error);
            throw error;
        }).finally(() => {
        });
}

function logout() {
    window.sessionStorage.removeItem("dpi_user");
    return true;
}

function handleResponse(response) {    
    const data = response.data;
    
    if (response.status !== 200) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }

    return data;
}