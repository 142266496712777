<template>
    <span v-if="isLoggedIn">
        <!--vv-btn v-if="false && $route.name !== 'home'" class="mr-2" icon color="secondary" title="Back" @click="$router.back()">
            <v-icon>mdi-arrow-left-box</v-icon>
        </!--vv-btn>             
        <-btn class="mr-2" icon color="secondary" :to="'/profile'" title="View your profile">
            <v-icon>mdi-account-circle</v-icon>
        </!--v-btn-->
        <span class="d-none d-md-inline-block mr-2" style="top: 2px; position: relative;">{{currentUser.username}}</span>
        <v-btn icon color="secondary" :to="'/home'" title="Home">
            <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-btn icon color="secondary" v-on:click="logOut()" title="Log out">
            <v-icon>mdi-logout</v-icon>
        </v-btn>
    </span>
</template>
<script>
import Auth from '../../mixins/auth';

export default {    
    name: "userInfo",
    mixins: [ Auth ],
    data() {
        return {
            submitted: false,
            username: "Sergey"            
        };
    },    
    methods: {      
      logOut() {
          this.$store.dispatch("authentication/logout", { root: true });
      },      
    }
};
</script>
