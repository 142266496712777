<template>
    <v-card dense class="d-flex align-content-end flex-wrap" :loading="loading">
        <v-form autocomplete="off" ref="form" style="width:100%">
            <div class="mt-8 mb-8" v-if="loading">Loading Inventory..</div>
            <div class="mt-8 mb-8" v-if="noInventory">No inventory found</div>
            <v-card-title v-if="inventory">Viewing Ending Inventory for {{formatDate(inventory.endInventoryDateStr)}}<span class="ml-2" v-if="inventory.isEditable">(Closed)</span></v-card-title>
            <v-card-text>
                <v-container fluid v-if="inventory">
                    <v-row :style="getAlternateRowStyle(index)" no-gutters v-for="(item, index) in inventory.items" :key="index">
                        <v-col xs="5" sm="7" md="7" lg="7" class="pr-1">
                            <label class="d-block" v-if="index === 0"><b>Item</b></label>
                            <!--    <v-autocomplete dense hide-no-data hide-selected :readonly="true"
                                    v-model="item.itemId"
                                    :items="itemLookup"
                                    :loading="loading"
                                    item-text="displayName"
                                    item-value="itemId"
                                    placeholder="Start typing to Search">
                    </v-autocomplete>
                    -->
                            <span>{{item.itemDisplayName}}</span>
                        </v-col>
                        <v-col xs="3" sm="2" md="2" lg="2" class="pr-1">
                            <label class="d-block" v-if="index === 0"><b>Quantity</b></label>
                            <span>{{item.quantity}}</span>
                        </v-col>
                        <v-col xs="4" sm="3" md="3" lg="3">
                            <label class="d-block" v-if="index === 0"><b>UoM</b></label>
                            <span>{{item.unitDisplayName}}</span>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-app-bar bottom fixed color="secondary" dark class="d-print-none">
          <v-card-actions>
            <v-btn dense color="primary" title="Go Back" @click="goBack()">
              <v-icon>mdi-arrow-left-thick</v-icon>
            </v-btn>     
          </v-card-actions>
        </v-app-bar>
            </v-card-actions>
        </v-form>
    </v-card>
</template>
<script>
    //import Auth from '../../mixins/auth';
    //import Common from '../../mixins/common';

    import http from "../../http";
    export default {
        name: "ViewPreviousInventory",
        //mixins: [ Auth, Common ],
        data() {
            return {
                inventory: null,     
                noInventory: false,           
                loading: false,
                itemLookup: [],
                unitLookup: []
            };
        },
        components: {
        },
        methods: {      
            goBack() {
                this.$router.push("/home");
            },                                              
            reload() {
                const that = this;
                const promises = [];

                that.loading = true;
                that.noInventory = false;
                const month = this.inventoryContext.previousInventoryMonth;
                const year = this.inventoryContext.previousInventoryYear;
                const url = this.$route.params.storeId
                    ?  `/api/inventory/previous-inventory/${this.$route.params.storeId}/${month}/${year}`
                    : `/api/inventory/previous-inventory/${this.currentStoreId}/${month}/${year}`;
                promises.push(http.get(url)
                    .then(this.handleResponse)
                    .then(response => {
                        if (response.message) {
                            that.noInventory = true;
                            that.$store.dispatch("alert/error", response.message);
                        }                        
                        that.inventory = response.content;                        
                    }).catch((error) => {
                        that.noInventory = true;
                        that.$store.dispatch("alert/error", error);
                    }).finally(() => {
                    }));
              
                Promise.all(promises).finally(() => {
                    that.loading = false;
                });
            },            
        },
        mounted() {
            if (!this.inventoryContext) {
                this.$router.push("/home");
            }
            this.reload();
        }
    };
</script>
