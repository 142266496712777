<template>
  <span class="d-flex justify-center">
    <v-card
      raised
      :loading="isLoggingIn"
      :max-width="'40rem'"
      :min-width="'20rem'"
    >
      <v-card-title>Change Password</v-card-title>
      <v-card-text>
        <v-form v-model="isLoginFormValid">
          <v-row>
            <v-col>
              <v-text-field
                placeholder="Old Password"
                :rules="[formValidationRules.required]"
                :type="revealPassword ? 'text' : 'password'"
                dense
                class=""
                v-model="password"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                placeholder="New Password"
                :rules="[formValidationRules.required]"
                :type="revealPassword ? 'text' : 'password'"
                class=""
                v-model="newPassword"
                v-on:keyup.enter="passwordEnterKeyUp()"
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                placeholder="Confirm New Password"
                :rules="[
                  formValidationRules.required,
                  passwordConfirmationRule,
                ]"
                :type="revealPassword ? 'text' : 'password'"
                class=""
                v-model="passwordConfirmation"
                v-on:keyup.enter="passwordEnterKeyUp()"
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                :loading="isLoggingIn"
                :disabled="loginButtonDisabled"
                color="secondary"
                class=""
                title="Change"
                @click="changePassword()"
                >Change</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </span>
</template>
<script>
import http from "../../http";
import router from "../../router";

export default {
        name: "ChangePassword",
        data() {
            return {
                submitted: false,
                password: null,
                newPassword: null,
                passwordConfirmation: null,
                revealPassword: false,
                isLoginFormValid: false                
            };
        },
        computed: {
            loginButtonDisabled() {
                return !this.isLoginFormValid;
            },
            passwordConfirmationRule() {
                return () => (this.newPassword === this.passwordConfirmation) || 'Password must match'
            }
        },
        methods: {
            passwordEnterKeyUp() {
                if (!this.loginButtonDisabled && !this.isLoggingIn) {
                    this.changePassword();
                }
            },
            changePassword() {
                const that = this;
                this.submitted = true;
                const model = {userId: this.currentUserId, "password":this.password, "newPassword":this.newPassword, "passwordConfirmation":this.passwordConfirmation };

                if (this.password && this.newPassword && this.passwordConfirmation) {                    
                    http.patch("api/user/change-password", model).then(this.handleResponse).then((response) => {
                        if (!response.error) {
                            if (that.isMultipleStores) {                             
                              router.push("/store-role-selector").catch(() => { });
                            } else {
                              router.push("/home").catch(() => { });
                            }
                        } else {
                            that.$store.dispatch("alert/error", response.error);
                        }
                    })
                  .catch((error) => {
                    that.$store.dispatch("alert/error", error);
                     });
                }
            },

            togglePasswordVisibility: function () {
                const inputType = document.querySelector("#password").getAttribute("type");
                document.querySelector("#password").setAttribute("type", inputType === "text" ? "password" : "text");
            },
        }
};
</script>
